<template>
  <div>
    <!-- select 2 demo -->

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Category Name"
              invalid-feedback="Category name is required."
              ref="name"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter category name"
                name="classField"
                ref="name"
                v-model="myObj.category"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Resolve Complaint</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="note"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter note"
                name="classField"
                ref="note"
                v-model="resObj.note"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config"
                v-model="resObj.resolvedOn"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Resolved By"
              invalid-feedback="resolved by is required."
              ref="resBy"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="resBy"
                v-model="resObj.resolvedBy"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>
                {{ resObj.status == "pending" ? "Resolve" : "Save" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Complaint</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row v-if="compObj.sID == 0">
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="loadSearch()"
                style="cursor: pointer"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="stdQuery"
                @keyup.enter="loadSearch()"
                placeholder="Search Student"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
          <b-col md="12">
            <b-table
              class="mt-1"
              show-empty
              ref="selectableTable"
              :items="students"
              :fields="stdFields"
              :busy="studentLoading"
              responsive
              hover
              selectable
              select-mode="single"
              @row-selected="getStudent"
              small
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #head(details)="data">
                <div class="text-center">
                  <span>{{ data.label }}</span>
                </div>
              </template>
              <template #cell(details)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="42"
                    :src="data.item.picture"
                    variant="light-secondary"
                  />
                  <div class="chat-info flex-grow-1 ml-2">
                    <h5 class="mb-0">
                      {{ data.item.name }}
                    </h5>
                    <p class="card-text text-truncate">
                      {{ data.item.meta_class }}
                    </p>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-form-group label="Student">
              <b-col class="mb-1 d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="42"
                    :src="currentStd.picture"
                    variant="light-secondary"
                  />
                  <div class="chat-info flex-grow-1 ml-2">
                    <h5 class="mb-0">
                      {{ currentStd.name }}
                    </h5>
                    <p class="card-text text-truncate">
                      {{ currentStd.meta_class }}
                    </p>
                  </div>
                </div>
                <b-button
                  @click="compObj.sID = 0"
                  class="btn-icon ml-3"
                  v-b-tooltip.hover.right
                  title="Remove"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Complaint Category"
              invalid-feedback="Complaint Category is required."
              ref="catId"
            >
              <v-select
                v-model="compObj.categoryID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :clearable="false"
                ref="catId"
                label="category"
                :reduce="(val) => val.id"
                placeholder="Select category"
                @input="CheckCatID()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="comp_date"
            >
              <flat-pickr
                ref="comp_date"
                :config="config"
                v-model="compObj.date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Complaint For"
              invalid-feedback="Complaint For is required."
              ref="about"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="e.g Teacher name"
                name="classField"
                ref="about"
                v-model="compObj.about"
                @focusout="CheckAbout()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Complaint By"
              invalid-feedback="Complaint by is required."
              ref="compBy"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="compBy"
                v-model="compObj.complaintBy"
                @focusout="CheckCompBy()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Complaint"
              invalid-feedback="Complaint is required."
              ref="details"
            >
              <b-form-textarea
                ref="details"
                placeholder="Write complaint"
                v-model="compObj.details"
                rows="5"
                @input="CheckDetails()"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="saveComplaint()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-modal
      id="modal-reason"
      hide-footer
      hide-header
      centered
      title="Reason"
      size="sm"
    >
      <div class="p-1">
        <h5>State Your Reason</h5>
        <b-form-input
          id="mc-first-name"
          placeholder="Enter your reason"
          ref="reason"
          v-model="resObj.reason"
        />
        <b-row class="mt-1">
          <b-col md="12" class="text-right">
            <b-button variant="success" @click="save()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Resolve </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-card>
      <b-row align-h="end" class="text-right">
        <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
          <b-dropdown
            style="margin-inline: 2px; margin-bottom: 2px"
            v-for="(d, ind) in categories"
            :key="ind"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            split
            :text="d.category"
            :variant="current == d.id ? 'primary' : 'outline-primary'"
            right
            @click="LoadGrid(d.id)"
          >
            <b-dropdown-item v-if="rights.edit" @click="Edit(d)">
              Edit
            </b-dropdown-item>
            <b-dropdown-item v-if="rights.delete" @click="Delete(d.id)">
              Delete
            </b-dropdown-item>

            <b-dropdown-divider />
          </b-dropdown>
          <!-- </div> -->
        </b-col>
        <b-col
          v-if="rights.add"
          class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          style="margin-right: 8px"
          cols="*"
        >
          <b-button
            @click="CatOpen()"
            style=""
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon size="16" icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="2" lg="2" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="AddOpen()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Complaint</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="5"
          lg="5"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col xl="5" lg="5" md="12">
          <b-row>
            <b-col sm="4" class="pl-lg-0">
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="currentStatus == '' ? 'primary' : 'outline-primary'"
                class="btn-icon mb-1 mb-sm-0"
                @click="filterData('')"
              >
                All ({{ allItems.length }})
              </b-button>
            </b-col>
            <b-col sm="4" class="pl-lg-0">
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="
                  currentStatus == 'pending' ? 'primary' : 'outline-primary'
                "
                class="btn-icon mb-1 mb-sm-0"
                @click="filterData('pending')"
              >
                Pending ({{ pendingCount }})
              </b-button>
            </b-col>
            <b-col sm="4" class="pl-lg-0">
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="
                  currentStatus == 'resolved' ? 'primary' : 'outline-primary'
                "
                class="btn-icon mb-1 mb-sm-0"
                @click="filterData('resolved')"
              >
                Resolved ({{ resolveCount }})
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        :items="filters"
        :fields="fields"
        :busy="gridLoading"
        show-empty
        responsive
        hover
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(date)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{
              new Date(data.item.us.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(complaintBy)="data">
          {{ data.item.us.complaintBy }}
        </template>

        <template #cell(student)="data">
          <div style="display: flex">
            <b-img
              v-if="data.item.picture !== null && data.item.picture !== ''"
              rounded
              :src="data.item.picture"
              height="75px"
              width="75px"
              alt=""
            />
            <b-img
              v-else
              rounded
              src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
              height="75px"
              width="75px"
            />
            <div class="ml-1">
              <p class="mb-1">{{ data.item.name }}</p>
              <b-badge variant="light-primary">
                <span>{{ data.item.cls }} - {{ data.item.sec }}</span>
              </b-badge>
            </div>
          </div>
        </template>

        <template #cell(about)="data">
          {{ data.item.us.about }}
        </template>

        <template #cell(details)="data">
          <span>{{ data.item.us.details }}</span>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-primary">
            <span>{{ data.item.us.status }}</span>
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              :title="data.item.us.status == 'pending' ? 'Resolve' : 'View'"
              class="btn-icon"
              @click="resolve(data.item.us)"
            >
              <feather-icon
                :icon="
                  data.item.us.status == 'pending' ? 'CheckIcon' : 'EyeIcon'
                "
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BAvatar,
    BModal,
    flatPickr,
    BImg,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData();
      this.LoadGrid(0);
    }

    // this.LoadData();
    // this.LoadGrid(0);
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.us.complaintBy.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      categories: [],
      request: false,
      gridLoading: false,
      colorDep: [],
      visibility2: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      sidebarTitle: "Add Complaint Category",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "student", key: "student" },
        { label: "complaint by", key: "complaintBy" },
        { label: "date", key: "date" },
        { label: "about", key: "about" },
        // { label: "category", key: "category" },
        { label: "details", key: "details" },
        { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      allItems: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      resObj: {},
      compObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        categoryID: 0,
        sID: 0,
        about: "",
        details: "",
        date: "",
        status: "pending",
        complaintBy: "",
        note: "",
        resolvedBy: "",
        resolvedOn: null,
      },
      visibility3: false,
      stdFields: [{ label: "Students", key: "details" }],
      students: [],
      studentLoading: false,
      stdQuery: "",
      currentStd: {},
      currentStatus: "",
      pendingCount: 0,
      resolveCount: 0,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    filterData(st) {
      this.currentStatus = st;
      if (st == "") {
        this.items = this.allItems;
      } else {
        this.items = this.allItems.filter(
          (el) => el.us.status.toLowerCase() == st
        );
      }
    },

    async loadSearch() {
      this.studentLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "Students/Search?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&keyword=" +
          this.stdQuery,
        token: this.$store.state.userData.token,
      });
      this.students = myitem;
      // console.log("stud", this.students);
      this.studentLoading = false;
    },
    getStudent(row) {
      this.compObj.sID = row[0].id;
      this.currentStd = row[0];
    },
    async AddOpen() {
      this.compObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        categoryID: 0,
        sID: 0,
        about: "",
        details: "",
        date: new Date().toJSON(),
        status: "pending",
        complaintBy: "",
        note: "",
        resolvedBy: "",
        resolvedOn: null,
      };
      this.visibility3 = true;
      var elem = this.$refs["details"];
      elem.state = undefined;
      var elem = this.$refs["compBy"];
      elem.state = undefined;
      var elem = this.$refs["about"];
      elem.state = undefined;
      var elem = this.$refs["catId"];
      elem.state = undefined;
    },
    CheckStudent() {
      var elem = this.$refs["catId"];
      if (this.compObj.sID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatID() {
      var elem = this.$refs["catId"];
      if (this.compObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAbout() {
      var elem = this.$refs["about"];
      if (this.compObj.about == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCompBy() {
      var elem = this.$refs["compBy"];
      if (this.compObj.complaintBy == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetails() {
      var elem = this.$refs["details"];
      if (this.compObj.details.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveComplaint() {
      this.CheckCatID();
      this.CheckAbout();
      this.CheckCompBy();
      this.CheckDetails();
      if (
        this.CheckStudent() == true &&
        this.CheckCatID() == true &&
        this.CheckAbout() == true &&
        this.CheckCompBy() == true &&
        this.CheckDetails() == true
      ) {
        this.request = true;
        // console.log("ob", this.compObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "SchoolComplaints?db=" +
            this.$store.state.userData.db,
          body: this.compObj,
          message: "Complaint added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;

        if (status) {
          this.LoadGrid(0);
          this.visibility3 = false;
        }
      }
    },

    CatOpen() {
      this.myObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add Complaint Category";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
    },
    Edit(item) {
      this.myObj = item;
      this.visibility = true;
      this.sidebarTitle = "Edit Complaint Category";
      this.sidebarButton = "Update";
      var elem = this.$refs["name"];
      elem.state = undefined;
    },

    resolve(item) {
      // this.$bvModal.show("modal-reason");
      this.resObj = item;
      this.visibility2 = true;
      var elem = this.$refs["note"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
      var elem = this.$refs["resBy"];
      elem.state = undefined;
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.category == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckNote() {
      var elem = this.$refs["note"];
      if (this.resObj.note == "" || this.resObj.note == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (this.resObj.resolvedOn == "" || this.resObj.resolvedOn == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckResName() {
      var elem = this.$refs["resBy"];
      if (this.resObj.resolvedBy == "" || this.resObj.resolvedBy == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "ComplaintCategories?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);

      this.dataLoading = false;
      // console.log("cat", this.categories);
    },
    async LoadGrid(id) {
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "SchoolComplaints/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&categoryID=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      this.allItems = [...this.items];
      this.pendingCount = this.allItems.filter(
        (el) => el.us.status.toLowerCase() == "pending"
      ).length;
      this.resolveCount = this.allItems.filter(
        (el) => el.us.status.toLowerCase() == "resolved"
      ).length;
      this.filterData("");
      // console.log("items", this.items);
      this.gridLoading = false;
    },

    async Add() {
      if (this.CheckName() == true) {
        // console.log("obj", this.myObj);
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "ComplaintCategories?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Complaint Category added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "ComplaintCategories/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Complaint Category updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;

          if (status) {
            this.LoadData();
            this.visibility = false;
          }
        }
      }
    },
    async save() {
      // console.log("ob", this.resObj);
      this.CheckNote();
      this.CheckDate();
      this.CheckResName();
      if (
        this.CheckNote() == true &&
        this.CheckDate() == true &&
        this.CheckResName() == true
      ) {
        this.request = true;
        this.resObj.status = "resolved";
        var status = await this.put({
          url:
            this.$store.state.domain +
            "schoolComplaints/" +
            this.resObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.resObj,
          message: "Complaint resolved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;

        if (status) {
          this.LoadGrid(0);
          this.visibility2 = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "ComplaintCategories/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Complaint category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
